import * as COMMON from '../../constants';
import { CLASS_NAMES } from './constants';
/**
 * @export
 * @class Dialog
 * @typedef {Dialog}
 */
export class Dialog {
    /**
     * Creates an instance of Dialog.
     *
     * @constructor
     * @param {HTMLElement} domNode trigger
     */
    constructor(domNode) {
        var _a, _b;
        this.option = {
            type: "dialog",
            autoOpen: false
        };
        this.triggers = new Set();
        this.targets = [];
        this.isOpen = false;
        this.trigger = domNode;
        this.triggers.add(this.trigger);
        this.bodyElement = document.getElementById('body') || document.body;
        this.overlayElement = document.getElementById('overlay');
        const controlsIds = ((_a = this.trigger.getAttribute('aria-controls')) === null || _a === void 0 ? void 0 : _a.split(/\s/)) || [];
        this.option = {
            type: this.trigger.dataset.dialogType || 'dialog',
            autoOpen: this.trigger.dataset.dialogAutoOpen === 'true'
        };
        controlsIds.forEach(id => {
            const target = document.getElementById(id);
            if (target) {
                this.targets.push(target);
                const closeButtons = target.getElementsByClassName(CLASS_NAMES.CLOSE_BUTTON);
                for (let i = 0; i < closeButtons.length; i++) {
                    const button = closeButtons[i];
                    this.triggers.add(button);
                }
            }
        });
        this.isOpen = this.trigger.getAttribute('aria-expanded') === 'true';
        this.triggers.forEach(trigger => trigger.addEventListener('click', this.onClick.bind(this)));
        (_b = this.overlayElement) === null || _b === void 0 ? void 0 : _b.addEventListener('click', this.close.bind(this));
    }
    /**
     * @method onClick handleEvent
     * @param {Event} e
     * @returns {void}
     */
    onClick(e) {
        e.preventDefault();
        this.handle(!this.isOpen);
    }
    onKeydown(e) {
        e.preventDefault();
        const key = e.key;
        if (COMMON.KEY.ESC.some(k => k === key)) {
            this.close.bind(this);
            document.removeEventListener('keydown', this.onKeydown);
        }
    }
    /**
     * @method handleDropdown handle DOM updates
     * @param {boolean} open is dropdown opened
     */
    handle(open) {
        // don't do anything if the open state doesn't change
        if (open === this.isOpen)
            return;
        this.toggleAttributes(open);
        // update the internal state
        this.isOpen = open;
        if (open) {
            document.addEventListener('keydown', this.onKeydown.bind(this));
        }
    }
    /**
     * @method toggleAttributes toggle elements attribtues
     * @param {boolean} open
     * @returns void
     */
    toggleAttributes(open) {
        // handle DOM updates
        this.triggers.forEach(trigger => {
            trigger.setAttribute('aria-expanded', `${open}`);
        });
        if (this.option.type === 'modal') {
            this.bodyElement.classList.toggle('have_curtain', open);
            if (this.overlayElement) {
                this.overlayElement.setAttribute('aria-hidden', `${!open}`);
                this.overlayElement.classList.toggle('is-active', open);
            }
        }
        this.targets.forEach((target, i) => {
            if (target instanceof HTMLDialogElement) {
                target.open = open;
            }
            else {
                target.setAttribute('aria-hidden', `${!open}`);
                target.classList.toggle('is-active', open);
            }
        });
        if (open) {
            const focusableElements = [...this.targets[0].querySelectorAll(COMMON.FOCUSABLE_ELEMENTS.join(','))].filter(el => el.classList.contains(CLASS_NAMES.CLOSE_BUTTON));
            document.activeElement instanceof HTMLElement && document.activeElement.blur();
            focusableElements[0].focus({ focusVisible: false });
        }
        else {
            this.trigger.focus({ focusVisible: false });
        }
    }
    /** Add public open and close methods for convenience */
    open() {
        this.handle(true);
    }
    close() {
        this.handle(false);
    }
}
