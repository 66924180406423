import { Dropdown } from '../Dropdown';
import { CLASS_NAMES } from './constants';
/**
 * @export
 * @class Navigation
 * @typedef {Navigation}
 */
export class Navigation {
    /**
     * Creates an instance of Navigation.
     *
     * @constructor
     * @param {HTMLElement} element .js-nav
     */
    constructor(element) {
        this.nav = element;
        this.items = this.nav.getElementsByClassName(CLASS_NAMES.ITEM);
        this.anchors = this.nav.getElementsByTagName('a');
        const location = window.location;
        for (let i = 0; i < this.anchors.length; i++) {
            const anchor = this.anchors[i];
            const hrefUri = new URL(anchor.href, location.origin);
            const current = this.isCurrent(hrefUri, location);
            if (current) {
                this.setAttribute(anchor, current);
            }
            ;
        }
        for (let j = 0; j < this.items.length; j++) {
            const item = this.items[j];
            if (item instanceof HTMLElement && [...item.children].some(child => child.classList.contains(CLASS_NAMES.LIST))) {
                const dropdown = new Dropdown(item);
                dropdown.rootEl.querySelectorAll('[aria-current="page"]').length > 0 && dropdown.open();
            }
        }
    }
    /**
     * @method isCurrent
     * @param {URL} hrefUri
     * @param {Location} location
     * @returns {boolean}
     */
    isCurrent(hrefUri, location) {
        return (hrefUri.href === location.href);
    }
    /**
     * @method setAttribute
     * @param {HTMLAnchorElement} a
     * @param {boolean} current
     */
    setAttribute(a, current) {
        current && a.setAttribute('aria-current', 'page');
    }
}
