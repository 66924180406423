import * as COMMON from '../../constants';
import { CLASS_NAMES } from './constants';
/**
 * @export
 * @class Dropdown
 * @typedef {Dropdown}
 */
export class Dropdown {
    /**
     * Creates an instance of Dropdown.
     *
     * @constructor
     * @param {HTMLElement} domNode
     */
    constructor(domNode) {
        var _a, _b, _c, _d;
        this.triggers = new Set();
        this.targets = [];
        this.isOpen = false;
        this.rootEl = domNode;
        const dataDialogTriggers = this.rootEl.dataset.dialogTriggers;
        let triggers = [];
        if (dataDialogTriggers) {
            const dataDialogTriggersArr = dataDialogTriggers.split(/\s/);
            dataDialogTriggersArr.forEach(id => {
                const el = document.getElementById(id);
                el && triggers.push(el);
            });
        }
        else {
            triggers = [...this.rootEl.getElementsByClassName(CLASS_NAMES.TRIGGER)];
        }
        this.trigger = triggers[0];
        const controlsIds = ((_b = (_a = this.trigger) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-controls')) === null || _b === void 0 ? void 0 : _b.split(/\s/)) || [];
        for (let i = 0; i < triggers.length; i++) {
            const trigger = triggers[i];
            const controls = ((_c = trigger.getAttribute('aria-controls')) === null || _c === void 0 ? void 0 : _c.split(/\s/)) || [];
            controls.every(id => controlsIds.includes(id)) && this.triggers.add(trigger);
        }
        controlsIds.forEach((id) => {
            const element = document.getElementById(id);
            if (element)
                this.targets.push(element);
        });
        // open dropdown if it contains a tag which it's href is same as current path.
        this.isOpen = this.rootEl.getAttribute('open') !== null || ((_d = this.trigger) === null || _d === void 0 ? void 0 : _d.getAttribute('aria-expanded')) === 'true' || this.targets.some(target => target.classList.contains(COMMON.CLASS_NAMES.ACTIVE));
        // add event listeners
        this.triggers.forEach(trigger => {
            trigger.addEventListener('click', this.onButtonClick.bind(this), { passive: false });
        });
    }
    /**
     * @method handleEvent
     * @param {Event} e
     * @returns {void}
     */
    onButtonClick(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.rootEl.dataset.animStatus === "running" || ![...this.triggers].some(trigger => e.currentTarget === trigger))
            return;
        this.handleDropdown(!this.isOpen);
    }
    /**
     * @method handleDropdown handle DOM updates
     * @param {boolean} open is dropdown opened
     */
    handleDropdown(open) {
        // don't do anything if the open state doesn't change
        if (open === this.isOpen)
            return;
        /**
         * duration and easing
         */
        const animTiming = {
            duration: 200,
            easing: "ease-in"
        };
        /**
         * keyframes for closing dropdown
         */
        const closingAnimKeyframes = (content) => [
            {
                height: content.offsetHeight + 'px', // height: "auto"だとうまく計算されないため要素の高さを指定する
            }, {
                height: content.style.maxBlockSize || 0,
            }
        ];
        /**
         * keyframes for opening dropdown
         */
        const openingAnimKeyframes = (content) => [
            {
                height: content.style.maxBlockSize || 0,
            }, {
                height: content.offsetHeight + 'px',
            }
        ];
        // toggle slide animation
        this.rootEl.dataset.animStatus = "running";
        if (this.isOpen) {
            this.targets.forEach(target => {
                const closingAnim = target.animate(closingAnimKeyframes(target), animTiming);
                closingAnim.onfinish = () => {
                    this.toggleAttributes(open);
                    // remove dataset after the closing animation.
                    this.rootEl.dataset.animStatus = "";
                };
            });
        }
        else {
            this.targets.forEach(target => {
                this.toggleAttributes(open);
                // アニメーションを実行
                const openingAnim = target.animate(openingAnimKeyframes(target), animTiming);
                openingAnim.onfinish = () => {
                    // remove dataset after the opening animation.
                    this.rootEl.dataset.animStatus = "";
                };
            });
        }
        // update the internal state
        this.isOpen = open;
    }
    /**
     * @method toggleAttributes toggle elements attribtues
     * @param {boolean} open
     * @returns void
     */
    toggleAttributes(open) {
        // handle DOM updates
        this.triggers.forEach(trigger => {
            trigger.setAttribute('aria-expanded', `${open}`);
            trigger.classList.toggle(COMMON.CLASS_NAMES.ACTIVE, open);
            if (trigger instanceof HTMLElement && trigger.dataset.label) {
                // if trigger has data-label, replace it.
                const label = trigger.dataset.label;
                const innerText = trigger.innerText;
                trigger.innerText = label;
                trigger.dataset.label = innerText;
            }
        });
        this.targets.forEach(target => {
            target.setAttribute('aria-hidden', `${!open}`);
        });
        this.rootEl instanceof HTMLDetailsElement ? (this.rootEl.open = open) : this.rootEl.classList.toggle(COMMON.CLASS_NAMES.ACTIVE, open);
    }
    /** Add public open and close methods for convenience */
    open() {
        this.handleDropdown(true);
    }
    close() {
        this.handleDropdown(false);
    }
}
