export const PREFIX = {
    CLASS_NAME: ".",
    TAG_NAME: "#",
};
/**
 * @constant 汎用クラス名
 */
export const CLASS_NAMES = {
    ACTIVE: "is-active",
    OPEN: "is-open",
    SHOWN: "is-shown",
    HIDDEN: "is-hidden",
    SCROLLABLE: "is-scrollable",
    FIRST: "is-first",
    LAST: "is-last",
    SCROLLLOCKED: "is-scrollLock"
};
export const FOCUSABLE_ELEMENTS = [
    "a[href]",
    "area[href]",
    "input:not([disabled])",
    "select:not([disabled])",
    "textarea:not([disabled])",
    "button:not([disabled])",
    "iframe",
    "[tabindex]",
    "[contentEditable=true]"
];
export const KEY = {
    ESC: ["Escape", "Esc"]
};
