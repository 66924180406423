import * as DROPDOWN from "./modules/Dropdown/constants";
import * as NAVIGATION from "./modules/Navigation/constants/";
import * as DIALOG from "./modules/Dialog/constants/";
import { Dropdown } from "./modules/Dropdown";
import { Navigation } from './modules/Navigation';
import { Dialog } from './modules/Dialog';
/**
 * @constant { HTMLCollectionOf<Element> } dropDownList ドロップダウンのトリガー
 */
const dropdownList = document.getElementsByClassName(DROPDOWN.CLASS_NAMES.DROPDOWN);
const navList = document.getElementsByClassName(NAVIGATION.CLASS_NAMES.CONTAINER);
const dialogList = document.getElementsByClassName(DIALOG.CLASS_NAMES.TRIGGER);
window.addEventListener("load", function () {
    if (navList) {
        for (let i = 0; i < navList.length; i++) {
            const nav = navList[i];
            if (nav instanceof HTMLElement) {
                const navigation = new Navigation(nav); // init Navigation
            }
        }
    }
    if (dropdownList) {
        for (let j = 0; j < dropdownList.length; j++) {
            const element = dropdownList[j];
            if (element instanceof HTMLElement) {
                const dropdown = new Dropdown(element);
            }
        }
    }
    if (dialogList) {
        for (let k = 0; k < dialogList.length; k++) {
            const element = dialogList[k];
            if (element instanceof HTMLElement) {
                const dialog = new Dialog(element);
            }
        }
    }
});
